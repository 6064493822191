@import "./Variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
$mobile: 900px;
$medium: 1450px;

body {
    background: #fff;
}

* {
    font-family: 'Quicksand';

}

header {
    background: url('../../public/images/bg.svg') center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logoBox {
        width: 955px;
        height: 400px;
        display: flex;

        @media screen and (max-width: $medium) {
            width: 900px;

        }

        @media screen and (max-width: $mobile) {
            flex-direction: column;
            height: 100vh;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        .logo {
            background: url('../../public/images/logo.svg') center no-repeat;
            background-size: 100% 100%;
            width: 340px;
            height: 100%;

            @media screen and (max-width: $medium) {
                width: 50%;
                background: url('../../public/images/logo.svg') center no-repeat;
                background-size: 100% 100%;
            }

            @media screen and (max-width: $mobile) {
                width: 100%;
                height: 350px;
                margin-top: -50px;
            }
        }

        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1;

            span {
                width: 100%;
                font-size: 84px;
                text-align: left;
                font-weight: 500;
                letter-spacing: 0.2em;
                padding-left: 50px;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                @media screen and (max-width: $mobile) {
                    font-size: 50px;
                    padding-left: 0;

                }
            }

            @media screen and (max-width: $medium) {
                padding-left: 5px;
            }

            @media screen and (max-width: $mobile) {
                width: 50%;
                line-height: 40%;
                justify-content: center;
                flex: 0;
            }

            text-align: left;
            font-family:'Quicksand',
            sans-serif;
            font-size: 10em !important;
            line-height: 100%;
            font-weight: 500;
            letter-spacing: 0em;
            word-spacing: 5em;
        }

        .sub-title {
            display: none;

            @media screen and (max-width: $medium) {
                display: none;
            }

            @media screen and (max-width: $mobile) {
                flex-direction: column;
                align-items: center;
                padding: 20px;
                display: flex;
                margin-top: 10px;
                letter-spacing: 24px;

                span {
                    width: 100%;
                    text-align: left;

                }

            }
        }
    }

    .bottom {
        width: 100%;
        height: 50%;
        background: url('../../public/images/bottom.svg') bottom no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 0px;
        left: 0;

        @media screen and (max-width: $medium) {
            height: 150px;
        }
    }

    .sub-title {
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        letter-spacing: 40px;
        margin-top: 70px;
        text-align: center;
        width: 1450px;

        @media screen and (max-width: $medium) {
            letter-spacing: 20px;
            width: 70%;
        }

        @media screen and (max-width: $mobile) {
            display: none;
        }
    }
}

.features {
    width: 100%;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 10%;

    @media screen and (max-width: $medium) {
        flex-direction: column;
    }

    .feature {
        display: flex;
        flex: 1;
        height: 350px;

        @media screen and (max-width: $medium) {
            width: 100%;
            height: auto;
        }

        text-align: left;
        flex-direction: column;
        font-family: 'Quicksand',
        sans-serif;
        color: #fff;
        font-weight: 300;
        padding: 20px;

        .title {
            font-size: 36pt !important;

            @media screen and (max-width: $medium) {
                font-size: 3em !important;
            }

            @media screen and (max-width: $mobile) {
                font-size: 2em !important;
            }
        }

        .desc {
            font-size: 24pt !important;

            @media screen and (max-width: $medium) {
                font-size: 2em !important;
            }

            @media screen and (max-width: $mobile) {
                font-size: 1.5em !important;
            }
        }
    }
}

.home-section {
    position: relative;
    background-color: $homeBackground;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 500px;
    z-index: 0;
    background-size: cover;
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    min-height: 100vh;
    -webkit-box-shadow: inset -3px 105px 45px -63px rgba(0, 0, 0, 1);
    -moz-box-shadow: inset -3px 105px 45px -63px rgba(0, 0, 0, 1);
    box-shadow: inset -3px 105px 45px -63px rgba(0, 0, 0, 1);


    .form {
        width: 1379px;

        display: flex;
        flex-direction: row;
        background: #111111;
        border-radius: 31px;
        padding: 60px;

        @media screen and (max-width: $medium) {
            width: 100%;
        }

        @media screen and (max-width: $mobile) {
            padding: 20px;

        }
    }

    .form-box {
        width: 70%;
        flex-direction: column;
    }

    .form-box,
    .mug {

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mug {
        border-radius: 31px;
        min-height: 658px;
        height: 658px;
        width: 30%;
        background: url('../../public/images/unsplash.svg') center no-repeat;
        background-size: cover;

        .talk {
            background: url('../../public/images/mug.png') center no-repeat;
            background-size: 90%;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (max-width: $mobile) {
        .form-box {
            width: 70%;
        }

        .mug {
            width: 30%;
            background-size: contain;
        }
    }

    @media screen and (max-width: $mobile) {
        .form-box {
            width: 100%;
            position: relative;
            z-index: 99999999;
            padding: 10px;
        }

        .mug {
            display: none;
        }
    }

    .inputerror {
        color: red;
    }

    label {
        margin-right: 15px;
    }

    .contact-form {
        width: 100%;
        text-align: left;
        padding: 20px;
        padding-left: 80px;

        @media screen and (max-width: $medium) {
            padding-left: 40px;
        }

        @media screen and (max-width: $mobile) {
            padding: 0px;
        }

        .form-group {
            margin-bottom: 30px;

            input,
            textarea {
                background: transparent;
                border: none;
                color: #fff;
                border-bottom: 2px solid #fff;
                border-radius: 0;
                font-family: 'Quicksand';
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                height: 60px;
                letter-spacing: 0.15em;
                padding-left: 0px;
            }

            input::placeholder,
            textarea::placeholder {
                color: #fff
            }

            *:focus {
                outline: none;
                box-shadow: none !important;
            }

            .inputerror {
                padding: 5px 10px;
                border-radius: 10px;
                color: #af1b1b;
                float: right;
                font-size: 14px;
            }
        }

        .btn {
            font-family: 'Quicksand';
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.15em;
            color: #000;
            background: linear-gradient(102.19deg, #CEE4E0 5.61%, #BEB1EF 102.91%);
            box-shadow: 7px 1px 6px #000000;
            border-radius: 32px;
            padding: 15px;
            display: flex;
            width: 200px;
            text-align: center;
            border: none;
            outline: none;
            justify-content: center;
            align-items: center;
            float: right;
            margin-top: 30px;
        }
    }
}

.alert-success {
    border: none;
    text-align: left;
    align-items: center;
    margin-left: 0px;
    width: 100%;
    background: none;
    padding: 0;
    display: flex;
    flex-direction: column;

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;

        color: #FFFFFF;
    }
}

.alert-danger {
    background-color: #dd1c2c;
    color: #fff;
    border: none;
    float: right;
    font-size: 25px;
}

.contact-form-title {
    background: url('../../public/images/Title.svg') center no-repeat;
    background-size: contain;
    font-family: 'Quicksand';
    color: #80FFFF;
    font-size: 36pt !important;
    margin-bottom: 30px;
    width: 100%;
    height: 50px;

    @media screen and (max-width: $mobile) {
        height: 100px;
    }
}

.mission {
    width: 100%;
    padding: 20px;
    padding-top: 100px;
    background: url('../../public/images/Mask group.png') no-repeat;
    background-position: bottom 10px right 20%;
    background-size: contain;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;

    @media screen and (max-width: $mobile) {
        padding: 10px;

    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 62px;
        color: #fff;
        width: 70%;
        text-align: left;

        @media screen and (max-width: $mobile) {
            font-size: 30px;
            line-height: 45px;
        }
    }

}

footer {
    width: 100%;
    height: 80px;
    color: #fff;
    background: #000;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: $mobile) {
        flex-direction: column;
    }

    .social {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .border {
            width: 80px;
            height: 3px;
            background: #fff;
            border: none;
            margin: 0 20px;


        }

        a {
            width: 27px;
            height: 27px;
            margin: 0 10px;
        }

        .linkdin {
            background: url('../../public/images/linkdin.svg') right top no-repeat;
            background-size: 100%;
        }

        .twitter {
            background: url('../../public/images/twitter.svg') right top no-repeat;
            background-size: 100%;
            margin-top: 6px;
        }

        .instagram {
            background: url('../../public/images/instagram.svg') right top no-repeat;
            background-size: 100%;
        }
    }
}