// @use 'styles/_colors.scss'; // assuming a styles directory under src/
// @use '~nprogress/nprogress'; // loading a css file from the nprogress node module
@import '~bootstrap/scss/bootstrap.scss';
@import './Styles/Home.scss';
@import './Styles/Responsive.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}